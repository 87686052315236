<template>
    <div :class="theme.auth.auth">
        <ToggleTheme />
        <div :class="theme.auth.subcontainer">
            <form @submit.prevent="confirmRegistration">
                <LogoBlock/>
                <div :class="theme.auth.header">
                    <span :class="theme.auth.text">
                        Email Confirmation
                    </span>
                </div>
                <div :class="[theme.auth.content, s.col]">
                    <TextInput
                        :class="theme.auth.mbL"
                        title="Code"
                        placeholder="Confirmation code"
                        :value="confirmationCode"
                        @input-text="confirmationCode = $event"
                    />
                    <Button
                        :class="theme.auth.mbS"
                        is-submit
                        button-type="primary"
                        wide
                        :button-disabled="isConfirmSubmitDisabled"
                    >
                        <template>
                            Send code
                        </template>
                    </Button>
                    <Button
                        :class="theme.auth.mbS"
                        button-type="infoNoBackground"
                        wide
                        @click="signOut"
                    >
                        <template>
                            cancel
                        </template>
                    </Button>
                    <Button
                        button-type="link"
                        @click="resendCode"
                        :class="s.center"
                        :button-disabled="resendCodeTimer !== 0"
                    >
                        <template>
                            {{ resendCodeTimer !== 0 ? `Resend code ${resendCodeTimer}` : 'Resend code' }}
                        </template>
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/dist/validators.min';

import ToggleTheme from '@/components/Auth/components/ToggleTheme.vue';
import LogoBlock from '@/components/Auth/components/LogoBlock.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import theme from 'Theme';
import Button from 'Control/Button.vue';
import TextInput from 'Control/TextInput.vue';
import ApiError from 'Entities/ApiError';

export default {
    name: 'Auth.SignUp.Confirm',
    components: {
        ToggleTheme,
        LogoBlock,
        Button,
        TextInput,
    },
    data() {
        return {
            confirmationCode: '',
            isConfirmSubmitDisabled: false,
            resendCodeTimer: 0,
            resendCodeIntervalId: null,
            theme,
        };
    },
    validations() {
        return {
            confirmationCode: {
                required,
            },
        };
    },
    computed: {
        ...mapGetters({
            currentEmail: 'Auth/currentEmail',
        }),
    },
    methods: {
        async confirmRegistration() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.isConfirmSubmitDisabled = true;
                this.$store.commit(SET_LOADING_ON(undefined));

                try {
                    await this.$store.dispatch('Auth/confirmRegistration', {
                        code: this.confirmationCode,
                    });
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'Your email was successfully confirmed');
                    try {
                        await this.$router.push('/signin');
                    } catch {
                        await this.signOut();
                    }
                } catch (error) {
                    this.isConfirmSubmitDisabled = false;
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error confirming email code');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
        startTimer() {
            this.resendCodeTimer = 60;
            this.resendCodeIntervalId = setInterval(() => {
                if (this.resendCodeTimer) {
                    this.resendCodeTimer -= 1;
                } else {
                    clearInterval(this.resendCodeIntervalId);
                    this.resendCodeIntervalId = null;
                }
            }, 1000);
        },
        async resendCode() {
            try {
                await this.$store.dispatch('Auth/resendConfirmRegistrationCode', {
                    email: this.currentEmail,
                });
                await this.$store.dispatch('Notificator/showSuccessNotification', `A new confirmation code is on way to your email: <span style="font-weight: var(--fw-bold)">${this.currentEmail}</span>`);
                this.startTimer();
            } catch {
                this.startTimer();
            }
        },
        async signOut() {
            try {
                await this.$store.dispatch('Auth/logout');
                await this.$router.replace('/signin');
            } catch {
                document.location.href = '/signin';
            }
        },
    },
    async mounted() {
        const { isFirstTime } = this.$route.query;
        if (!isFirstTime) {
            await this.resendCode();
        }
    },
};
</script>

<style lang="postcss" module="s">
.goBack {
    position: absolute;
    top: -18px;
    font-weight: var(--fw-bold);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-violet);
    cursor: pointer;
    margin-bottom: var(--m-s);
}
.col {
    display: flex;
    flex-direction: column;
}
.center {
    margin: auto;
}
</style>
